import React ,{useState} from "react";
import { useQuery, useMutation } from "react-query";
import { client } from "../../utils/api-client";
  const formatSolde = (solde) => {
    return parseFloat(solde).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  };

  const getRoleDisplay = (role) => {


    if(role === "player") return "gray";
    if(role === "shop") return "yellow";
    if(role === "agent") return "red";


  } 

export default function Player(props) {

  const [isChildShown, setIsChildShown] = useState({});

  const toggleIsShown = (e, adminID, index) => {
    e.preventDefault();
    setIsChildShown({ [index]: !isChildShown[index] });
  };

  const [dataList, setdataList] = useState([]);

  const parentChildId = props.parentChildId;
  const childId       = props.childId;

  let uri = `admin/childs/${parentChildId}/${childId}`;
  const { data, isLoading, isError, error } = useQuery(uri, async (data) => {
    const token = window.localStorage.getItem("__super_auth_provider_token__");
    return client(uri, { token });
  });


React.useEffect(() => {

  if (data) {

    let item_order = ["agent","shop","player"];

    let filterArray = data.data.childs.sort((a, b) => item_order.indexOf(a.role) - item_order.indexOf(b.role));

    setdataList(filterArray)
  }
}, [data])



    return (
      <>
      { dataList.length > 0 &&
          dataList.map((child: any, index: any) => (
      <>          
        <li class="border-gray-400 flex flex-row mb-2">
          <div class="bg-red-300 transition duration-500 shadow ease-in-out transform hover:-translate-y-1 hover:shadow-lg select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
            <div class="flex-1 pl-1 md:mr-16">
              <div class="font-medium dark:text-white">{child.username}</div>
              <div class="text-gray-600 dark:text-gray-200 text-xs font-semibold">
                sport : {formatSolde(child.sold_sport)} TND | casino : {formatSolde(child.sold_casino)} TND | Live casino : {formatSolde(child.sold_livecasino)} TND
              </div>
            </div>
            <button class="w-16 text-center flex justify-center font-light  bg-red-700 text-white  rounded">
              {child.role}
            </button>
          </div>
        </li>
      </>
      ))}      
    </>        
    )
}
