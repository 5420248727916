// @ts-nocheck
import { queryCache } from "react-query";
import * as auth from "../context/auth/auth-provider";
const apiURL = process.env.REACT_APP_API_URL;
const sportsApiURL = process.env.REACT_APP_API_SPORTS_URL || "";

async function client(
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {},
  isSportsApi = false
) {
  const config = {
    method: data ? "POST" : "GET",
    mode: "cors",
    body: data ? JSON.stringify({ ...data, token }) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };
  if (isSportsApi) {
    console.log(`${sportsApiURL}/${endpoint}`);
    return window
      .fetch(`${sportsApiURL}/${endpoint}`, config)
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          return data;
        } else {
          return Promise.reject(data);
        }
      });
  } else
    return window
      .fetch(`${apiURL}/${endpoint}`, config)
      .then(async (response) => {
        if (response.status === 401) {
          //queryCache.clear();
          await auth.logout();
          // refresh the page for them
          window.location.assign(window.location);
          return Promise.reject({ message: "Please re-authenticate." });
        }

        const data = await response.json();
        if (data.status === true) {
          return data;
        } else {
          return Promise.reject(data);
        }
      });
}


async function cliento(
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {},
  isSportsApi = false
) {
  const config = {
    method: data ? "POST" : "GET",
    mode: "cors",
    body: data ? JSON.stringify({ ...data, token }) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };
  const URL = isSportsApi ? sportsApiURL : apiURL;
  return window.fetch(`${URL}/${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {

      console.log("error")
      window.location.assign(window.location);
      return Promise.reject({ message: "Please re-authenticate." });
    }

    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}


function _client(body,endpoint) {
  const url = process.env.REACT_APP_API_URL;
  var token = window.localStorage.getItem("__auth_provider_token__");

  const config = {
    method: "POST",
    body: JSON.stringify({ ...body }),
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json",
    },
  };

  return window
    .fetch(`${url}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();

      if (data.status === 200) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}


async function client_neutre(
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {},
  isSportsApi = false
) {
  const config = {
    method: data ? "POST" : "GET",
    mode: "cors",
    body: data ? JSON.stringify({ ...data, token }) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };
  const URL = isSportsApi ? sportsApiURL : apiURL;
  return window.fetch(`${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {

      console.log("error")
      window.location.assign(window.location);
      return Promise.reject({ message: "Please re-authenticate." });
    }

    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}



export { client, cliento, _client, client_neutre };
