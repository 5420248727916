import { useState } from "react";
import Agent from "../../components/agent/Agent";
import "./circuit.css";
import { useQuery, useMutation } from "react-query";
import { client } from "../../utils/api-client";

export default function Circuit() {
  const formatSolde = (solde) => {
    return parseFloat(solde).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  };
  const [isChildShown, setIsChildShown] = useState({});

  const toggleIsShown = (e, adminID, index) => {
    e.preventDefault();
    setIsChildShown({ [index]: !isChildShown[index] });
  };


  let uri = `admins-list`;
  const { data, isLoading, isError, error } = useQuery(uri, async (data) => {
    const token = window.localStorage.getItem("__super_auth_provider_token__");
    return client(uri, { token });
  });

  return (
    <div class="container flex flex-col mx-auto w-full items-center justify-center">
      <div class="px-4 py-5 sm:px-6 w-full border dark:bg-gray-800 bg-white shadow mb-2 rounded-md">
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white text-left">
          Circuit
        </h3>
      </div>
      <ul class="flex flex-col w-full">
        {data &&
          data.admins.length > 0 &&
          data?.admins?.map((admin: any, index: any) => (
            <>
              <li
                class="border-gray-400 flex flex-row mb-2"
                onClick={(e) => toggleIsShown(e, admin.id,index)}
              >
                <div class="transition duration-500 shadow ease-in-out transform hover:-translate-y-1 hover:shadow-lg select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                  <div class="flex-1 pl-1 md:mr-16">
                    <div class="font-medium dark:text-white">
                      {admin.username}
                    </div>
                    <div class="text-gray-600 dark:text-gray-200 text-xs font-semibold">
                      {formatSolde(admin.sold_sport)} TND
                    </div>
                  </div>
                  <button class="w-16 text-center flex justify-center font-light  bg-green-700 text-white  rounded">
                    Admin
                  </button>
                </div>
              </li>
              {isChildShown[index] && <Agent childId={admin.id} />}
            </>
          ))}
      </ul>
    </div>
  );
}
