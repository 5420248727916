import React from 'react'
import moment from 'moment';

import './widgetLg.css'

interface Props {
  data: any;
}

const formatSolde = (solde) => {

return (parseFloat(solde)).toLocaleString(
                                          undefined,
                                          { minimumFractionDigits: 2 }
                                        );
}

const formatDate = (date) => {

    //return date
    return moment(date).format('YYYY-MM-DD HH:mm:ss')

}

const WidgetLg: React.FC<Props> = ({ data }) => {

    const Button = ({type})=>{

        let class_name;

        if(type=== "+"){

            class_name = "Deposit"

        }else if(type=== "-"){

            class_name = "Retrait"

        }

        return <button className={"widgetLgButton " + class_name }>{class_name} {type}</button>

    }

    return (
        <div className="widgetlg">
            <h3 className="widgetLgTitle">Transactions History</h3>
            <table className="widgetLgTable">
                <tr className="widgetLgTr">
                    <th className="widgetLgTh">Id</th>
                    <th className="widgetLgTh">Date</th>
                    <th className="widgetLgTh">Amount</th>
                    <th className="widgetLgTh">Type</th>
                </tr>
                {data && data.length > 0 && 
                    data?.map((transaction: any, index: any) => (
                    <>
                    <tr className="widgetLgTr">
                    <td className="widgetLgId">
                        <span className="widgetLgid_text">
                            #{transaction.id}
                        </span>

                    </td>
                    <td className="widgetLgDate">
                        {formatDate(transaction.created_at)}
                    </td>
                    <td className="widgetLgAmount">
                        {formatSolde(transaction.amount)} TND
                    </td>
                    <td className="widgetLgStatus">
                        <Button type={transaction.sign}/>
                    </td>
                     </tr>
                     </>
                     ))}
               
            </table>
        </div>
    )
}

export default WidgetLg;
