import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { CashIcon } from '@heroicons/react/outline'
import "./betslipList.css";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { client } from "../../utils/api-client";
import DepositBetlip from "./DepositBetlip";

const formatSolde = (solde) => {
  return parseFloat(solde).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};


const isEmpty = (value): boolean => {
  if (value === null) {
    return true;
  } else if (typeof value !== 'number' && value === '') {
    return true;
  } else if (typeof value === 'undefined' || value === undefined) {
    return true;
  } else if (value !== null && typeof value === 'object' && !Object.keys(value).length) {
    return true;
  } else {
    return false;
  }
};

const getPath = (data) => {

    let str = "";

    if(!isEmpty(data.supero)) str += ` ${data.supero} ➤`
    if(!isEmpty(data.admin)) str += ` ${data.admin} ➤`
    if(!isEmpty(data.agent)) str += ` ${data.agent} ➤`
    if(!isEmpty(data.shop)) str += ` ${data.shop} ➤`


    if(str.slice(-1) == "➤"){

          str = str.slice(0,-2)

    }

    return " • "+str;

}


export default function UserList() {


  let uri = `betslips-list`;
  const { data, isLoading, isError, error } = useQuery(uri, async (data) => {
    const token = window.localStorage.getItem("__super_auth_provider_token__");
    return client(uri, { token });
  });


  const [showModal, setShowModal] = React.useState(false);

  const [couponView, setCouponView] = React.useState({});
  const [events, setEvents] = React.useState({});

  const [FiltredCoupons, setFiltredCoupons] = React.useState([]);

  const [filter, setFilter] = useState('');


  const searchCoupon = (filerValue) => {


      setFilter(filerValue);

      let fit = data?.data[0].filter(coupon => coupon.coupon_code.includes(filerValue));

      setFiltredCoupons(fit);
      console.log(FiltredCoupons);

      
  };


  const [open, setOpen] = useState(false)
  const [depositCp, setDepositCp] = useState({})

  const cancelButtonRef = useRef(null)


  const showCouponTable = (data) => {

      setCouponView(data);

      setEvents(JSON.parse(data.json));
      console.log(events)
      setShowModal(true)
  }

  const showCouponDeposit = (data) => {

      setDepositCp(data);
      setOpen(true);
  }
  return (
    <div>
    <div className=" mx-4 sm:px-8 w-full">
      <div className="py-8">
        <div className="flex flex-row mb-1 justify-between w-full ">
          <h2 className="text-2xl leading-tight">Verify Won Betslips</h2>
          <div className="text-end">
            <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
              <div className=" relative flex lg:flex-row space-x-2 sm:flex-col ">
                <input
                  type="text"
                  id='"form-subscribe-Filter'
                  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full lg:py-2 lg:px-4 sm:py-1 sm:px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600"
                  placeholder="coupon code"
                  value={filter}
                  onChange={event => searchCoupon(event.target.value)}
                />
              </div>
            </form>
          </div>
        </div>
        <div className=" py-4 ">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal pl-2"
                  >
                    Coupon
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  font-bold text-left text-sm uppercase font-normal"
                  >
                    gain
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal"
                  >
                    Player
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal"
                  >
                    Betslip path
                  </th>                  
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                { (data && data.data.length > 0 && filter.length < 2)  ?
                  data?.data[0]?.map((coupon: any, index: any) => (
                    <>
                      <tr >

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-900 whitespace-no-wrap text-left pl-2">
                          #{coupon.coupon_code}
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex justify-start text-left">
                            <button className="betslipGain">{formatSolde(coupon.gainMax)} DT</button>
                        </td>

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-900 whitespace-no-wrap text-left">
                          {coupon.user}
                        </td>

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-600 whitespace-no-wrap text-left">
                          {getPath(coupon)}
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex justify-start text-left">
                            <button onClick={() => showCouponDeposit(coupon)} className="userListEdit">Set</button>
                            <button onClick={() => showCouponTable(coupon)} className="betslipSob">Events</button>
                        </td>

                      </tr>
                    </>
                  )) 
                  : FiltredCoupons?.map((coupon: any, index: any) => (
                    <>
                      <tr >

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-900 whitespace-no-wrap text-left pl-2">
                          #{coupon.coupon_code}
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex justify-start text-left">
                            <button className="betslipGain">{formatSolde(coupon.gainMax)} DT</button>
                        </td>

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-900 whitespace-no-wrap text-left">
                          {coupon.user}
                        </td>

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-600 whitespace-no-wrap text-left">
                          {getPath(coupon)}
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex justify-start text-left">
                            <button onClick={() => setShowModal(true)} className="userListEdit">Set</button>
                            <button onClick={() => showCouponTable(coupon)} className="betslipSob">Show</button>
                        </td>

                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
            <div className="px-auto bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
              <div className="flex items-center">
                <button
                  type="button"
                  className="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100"
                >
                  <svg
                    width="9"
                    fill="currentColor"
                    height="8"
                    className=""
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  className="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 "
                >
                  1
                </button>
                <button
                  type="button"
                  className="w-full p-4 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100"
                >
                  <svg
                    width="9"
                    fill="currentColor"
                    height="8"
                    className=""
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto w-full h-full bg-white opacity-95 my-6 mx-auto max-w-6xl"
                style={{marginTop : '101px'}} >
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                   #{couponView.coupon_code}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">


            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal pl-2"
                  >
                    Events ({events.length})
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  font-bold text-left text-sm uppercase font-normal"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal"
                  >
                    Period
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal"
                  >
                    Score
                  </th>  

                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal"
                  >
                    Cota
                  </th>                  


                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800 font-bold text-left text-sm uppercase font-normal"
                  >
                    State
                  </th>
                </tr>
              </thead>
              <tbody>
                {events &&
                  events.length > 0 &&
                  events.map((event: any, index: any) => (
                    <>
                      <tr >

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-900 whitespace-no-wrap text-left pl-2">
                          {event.event_name}
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex justify-start text-left">
                            {event.Fdate}
                        </td>

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-600 whitespace-no-wrap text-left">
                          { event.event_period === "" ? "-:-" : event.event_period }
                        </td>

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-900 whitespace-no-wrap text-left">
                          {event.event_score.ft_score}
                        </td>

                        <td className="px-5 py-5  border-b border-gray-200 bg-white text-sm text-gray-900 whitespace-no-wrap text-left">
                          {event.odds[0].market_name} : {event.odds[0].odd_name} <span className="text-sm text-gray-600">({event.odds[0].odd_value})</span>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex justify-start text-left">
                            <button className={`betslipGain ${event.event_state}`}>{event.event_state}</button>
                        </td>

                      </tr>
                    </>
                  ))}
              </tbody>
            </table>



                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      { open && (<DepositBetlip setOpen={setOpen} open={open} couponD={depositCp} />)}
      </div>
  );
}
