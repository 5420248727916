import React, { useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { client, _client } from "../../utils/api-client";

import "./user.css";
import {
  AccountCircle,
  SportsSoccer,
} from "@material-ui/icons";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import SoldeManage from "./SoldeManage";

const formatSolde = (solde) => {


return (parseFloat(solde)).toLocaleString(
                                          undefined,
                                          { minimumFractionDigits: 2 }
                                        );


}


export default function User() {


  let history = useHistory();


  const { userId }: { userId: string } = useParams();

  const token = window.localStorage.getItem("__super_auth_provider_token__");

  let uri = `admin/${userId}`
  var { data, isLoading, isError, error } = useQuery(uri,async (data) => {

        return client(uri,{token});

      });

  if(!isLoading){
      if(data){
          if(data.data.admin === null){
              history.push('/')
          }
      }
      if(error){
        history.push('/')
      }
  }
  if( isError){
            history.push('/')
  }




  return (
    <div className="Wrapping">
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Edit Admin</h1>

      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <img
              src="https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png"
              alt=""
              className="userShowImg"
            />
            <div className="userSHowTopTitle">
              <span className="userShowUsername">{data?.data?.admin?.username}</span>
            </div>
          </div>
          <div className="userShowBottom">
            <div className="userShowContainer">
              <div className="userShowLeft">
                <span className="userShowTitle">Account Details</span>
                <div className="userShowInfo">
                  <AccountCircle className="userShowIcon" />
                  <span className="userShowInfoTitle">{data?.data?.admin?.username}</span>
                </div>
                <div className="userShowInfo">
                  <SportsSoccer className="userShowIcon" />
                  <span className="userShowInfoTitle">Solde : {formatSolde(data?.data?.admin?.solde)} TND</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SoldeManage/>
        </div>
    </div>
    <WidgetLg data={data?.data?.history} />
    </div>
  );
}
