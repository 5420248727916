// @ts-nocheck
import React from "react";
import * as auth from "./auth-provider";
import { client } from "../../utils/api-client";
import { useAsync } from "../../hooks/hooks";

async function bootstrapAppData() {
  let user = null;

  const token = await auth.getToken();
  if (token) {
    const data = await client("me", { token });
    if (data.status === false) {
      auth.logout();
      auth.clearToken();
    } else {
      user = { ...data.user, token };
    }
  }
  return user;
}

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

function AuthProvider(props) {
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync();

  React.useEffect(() => {
    const appDataPromise = bootstrapAppData();

    run(appDataPromise);
  }, [run]);

  const login = React.useCallback(
    (form) =>
      auth.login(form).then((data) => {
        setData(data.user);
      }),
    [setData]
  );


  const refreshUserSimple = React.useCallback(() => {
    
      bootstrapAppData().then((data) => {
        setData(data);
      })

  }, [setData]);


  const refreshUser = React.useCallback(() => run(bootstrapAppData()), [run]);


  const logout = React.useCallback(() => {

    console.log("logout");
    auth.logout();
    //queryCache.clear();
    setData(null);
  }, [setData]);

  const value = React.useMemo(() => ({ user, login, logout, refreshUser, refreshUserSimple }), [
    login,
    logout,
    refreshUser,
    refreshUserSimple,
    user,
  ]);
  if (isLoading || isIdle) {
    return "loading ....";
  }

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useClient() {
  const { user } = useAuth();
  const token = user?.token;
  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token]
  );
}

export { AuthProvider, useAuth, useClient };
