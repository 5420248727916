import "./topbar.css";
import {  SupervisedUserCircle, Dns } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth/auth-context";

const formatSolde = (solde) => {
  return parseFloat(solde).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};

export default function Topbar() {
  const { user, logout } = useAuth();

  return (
    <div className="Parent">
      <div className="topbar">
        <div className="topbarWrapper">
          <div className="topLeft">
            <span className="logo">Dashboard</span>
          </div>
          <Link to="#" className="Link">
              <li className=" px-4 py-2 md:text-sm  text-white bg-green-500 rounded-lg text-xs flex flex-row justify-around align-center ">
                <span class="hidden md:block">{user.username} | </span> <span> &nbsp; {formatSolde(user?.solde)}  TND </span>
              </li>
            </Link>
          <div className="topRight">
            <a onClick={logout}>
              <button className="lex-shrink-0 px-4 py-2 text-base  text-white bg-red-500 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200">
                Logout
              </button>
            </a>
          </div>
        </div>
      </div>
     
    </div>
  );
}
