// @ts-nocheck

const localStorageKey = "__super_auth_provider_token__";

async function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

async function clearToken() {
  return window.localStorage.removeItem(localStorageKey);
}

function handleUserResponse(user: any) {
  window.localStorage.setItem(localStorageKey, user.token);
  return user;
}

function login({ username, password }: any) {

  return client("login", { username, password  }).then(handleUserResponse);

}

function register({ username, password }: any) {
  return client("register", { username, password }).then(handleUserResponse);
}

function handleUserLogout(response: any) {
  if (response.message === "User logged out successfully") {
    window.localStorage.clear();
  }
}

async function logout() {
  const token = window.localStorage.getItem(localStorageKey);

  return client(`logout?token=${token}`).then(handleUserLogout);
}

const authURL = process.env.REACT_APP_API_URL;

async function client(endpoint: any, data: any) {
  const config = {
    method: data ? "POST" : "GET",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  };


  return window
    .fetch(`${authURL}/${endpoint}`, config)
    .then(async (response) => {

      const data = await response.json();

      if (data.status === true) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}

export {
  getToken,
  clearToken,
  login,
  register,
  logout,
  localStorageKey,
  client,
};
