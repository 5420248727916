import React ,{useState, useRef} from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { client } from "../../utils/api-client";

import "./newUser.css";

function _client(body, endpoint) {
  const url = process.env.REACT_APP_API_URL;
  var token = window.localStorage.getItem("__super_auth_provider_token__");

  const config = {
    method: "POST",
    body: JSON.stringify({ ...body, token }),
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json",
    },
  };

  return window.fetch(`${url}/${endpoint}`, config).then(async (response) => {
    const data = await response.json();
    const respStatus = await response.status;

    if (data.status === true) {
      return data;
    } else {
      return Promise.reject(respStatus);
    }
  });
}

export default function NewUser() {

  let history = useHistory();

  const [errorMsg, seterrorMsg] = React.useState("");

  let uriPost = `user/create`;
  const { mutate, isLoading, isSuccess, isError, data, error, reset } =
    useMutation(async (data) => _client(data, uriPost), {
      onSuccess: (data) => {
        if (data.status) {
          if (data.message != undefined) {
            history.push("/users")
          } else if (data?.error != null) {
            seterrorMsg(data.error);
          }
        }
        //window.location.reload()
      },
      onError: (status) => {},
    });

  const usernameRef = useRef();
  const passwordRef = useRef();

  function actionHandle(event: any) {
    event.preventDefault();

    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    mutate({ username, password });
  }



  return (
    <div className="newUser">
      <h1 className="newUserTitle">New Admin</h1>
      <form className="newUserForm mx-auto">
        <div className="newUserItem">
          <label>Username</label>
          <input ref={usernameRef} type="text" name="username" placeholder="username" />
        </div>

        <div className="newUserItem">
          <label>Password</label>
          <input ref={passwordRef} type="password" name="password" placeholder="password" />
        </div>

        <button className="newUserButton" onClick={actionHandle}>Create</button>
      </form>
    </div>
  );
}
