import { SupervisedUserCircle, Dns, Assignment } from "@material-ui/icons";
import { Link } from "react-router-dom";
import './sidebar.css'
export default function Sidebar() {
    return (
        <div className="Sidebar">
        <div className="Menu">
          <ul className="MenuWrapper">

            <Link to="/users" className="Link">
              <li className="MenuItem">
                <Dns className="dashIcon" />
                Home
              </li>
            </Link>
            <Link to="/betslips" className="Link">
              <li className="MenuItem">
                <Assignment className="dashIcon" />
                Betslip
              </li>
            </Link>
            <Link to="/circuit" className="Link">
              <li className="MenuItem">
                <SupervisedUserCircle className="dashIcon" />
                Circuit
              </li>
            </Link>
          </ul>
        </div>
      </div>
    )
}
