import React, { useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

function _client(body, endpoint) {
  const url = process.env.REACT_APP_API_URL;
  var token = window.localStorage.getItem("__super_auth_provider_token__");

  const config = {
    method: "POST",
    body: JSON.stringify({ ...body, token }),
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json",
    },
  };

  return window.fetch(`${url}/${endpoint}`, config).then(async (response) => {
    const data = await response.json();
    const respStatus = await response.status;

    if (data.status === true) {
      return data;
    } else {
      return Promise.reject(respStatus);
    }
  });
}

export default function SoldeManage() {
  let history = useHistory();

  const { userId }: { userId: string } = useParams();

  const [errorMsg, seterrorMsg] = React.useState("");

  let uriPost = `admin/solde`;
  const { mutate, isLoading, isSuccess, isError, data, error, reset } =
    useMutation(async (data) => _client(data, uriPost), {
      onSuccess: (data) => {
        if (data.status) {
          if (data.message != undefined) {
            window.location.reload();
          } else if (data?.error != null) {
            seterrorMsg(data.error);
          }
        }
        //window.location.reload()
      },
      onError: (status) => {},
    });

  const amountRef = useRef();

  function actionHandle(event: any, action: any) {
    event.preventDefault();
    const amount = amountRef.current.value;
    const userid = userId;

    mutate({ userid, action, amount });
  }

  return (
    <div className="userUpdate">
      <span className="userUpdateTitle">Solde Management</span>
      <div className="userUpdateLeft">
        <div className="userUpdateItem">
          <label>Balance</label>
          <input
            type="number"
            name="amount"
            ref={amountRef}
            className="userUpdateInput"
            placeholder="solde en TND"
          />
        </div>
        <div className="flex justify-between">
          <button
            onClick={(e) => actionHandle(e, "deposit")}
            className="userUpdateButton"
          >
            deposit +
          </button>

          <button
            onClick={(e) => actionHandle(e, "retrait")}
            className="userUpdateButton"
          >
            retrait -
          </button>
        </div>
      </div>
      {errorMsg != "" && (
        <div className="self-center mt-10 text-xl font-light text-gray-600 md:text-md text-red-600">
          {errorMsg}
        </div>
      )}
    </div>
  );
}
