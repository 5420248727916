import React from "react";
import "./app.css";
import Topbar from "./components/topbar/Topbar";
import UserList from "./pages/userList/UserList";
import BetslipList from "./pages/betslipList/BetslipList";
import User from "./pages/user/User";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import NewUser from "./pages/newUser/NewUser";
import Circuit from "./pages/circuit/Circuit";
import Login from "./pages/login/Login";
import { useAuth } from "./context/auth/auth-context";
import Sidebar from "./components/sidebar/Sidebar";


function PrivateRoute({ children, ...rest }) {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  let { user } = useAuth();

  return (
    <Router>
      {user && <Topbar />}
      <div className="container">
        {user && (
          <div class="super">
            <Sidebar />
          </div>
        )}

        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <div class="bitch">
            <PrivateRoute path="/users">
              <UserList />
            </PrivateRoute>
            <PrivateRoute path="/betslips">
              <BetslipList />
            </PrivateRoute>            
            <PrivateRoute path="/user/:userId">
              <User />
            </PrivateRoute>
            <PrivateRoute path="/newUser">
              <NewUser />
            </PrivateRoute>
            <PrivateRoute path="/circuit">
              <Circuit />
            </PrivateRoute>
          </div>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
